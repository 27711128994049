import React from 'react'
import SEO from '../components/seo'
import GetMegaCarromParty from '../components/GetMegaCarromParty/GetMegaCarromParty'
import Header from '../components/Header/Header'
import DesktopStickyFooter from '../components/Download/DesktopStickyFooter'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const GetMegaCarromPartyContainer: React.FC = () => {
  return (
    <div>
      <SEO
        title="MegaCarromParty"
        description="MegaCarromParty"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
      <Header />
      <GetMegaCarromParty />
      <DesktopStickyFooter />
    </div>
  )
}

export default GetMegaCarromPartyContainer
