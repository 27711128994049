import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import formatNumber from '../../lib/format-number'
import './WinnerCard.scss'

interface Props {
  rank: number
  winner: string
  winnerImage: FixedObject
  views: string

  starName: string
  starImage: FixedObject
}

const WinnerCard: React.FC<Props> = ({
  rank,
  winner,
  winnerImage,
  views,
  starName,
  starImage,
}) => {
  const data = useStaticQuery(graphql`
    {
      yt: file(relativePath: { eq: "youtube-round-black.png" }) {
        childImageSharp {
          gatsbyImageData(width: 34, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `)

  return (
    <div className="WinnerCard">
      <div className="winner">
        <div className="info">
          <div className="rank">rank {rank}</div>
          <div className="yt">
            <div className="yt-image">
              <GatsbyImage
                image={data.yt.childImageSharp.gatsbyImageData}
                alt=""
                className="yt-image-img"
              />
            </div>
            <div>
              <div className="channel-name">{winner}</div>
              <div className="views">
                {formatNumber(parseInt(views.replace(/,/g, ''), 10))} Views
              </div>
            </div>
          </div>
        </div>
        <div>
          <GatsbyImage
            image={winnerImage}
            alt={winner}
            className="winner-image"
          />
        </div>
      </div>

      <div className="separator" />

      <div className="prize">
        <div className="star-image-wrap">
          <div className="star-image">
            <GatsbyImage
              image={starImage}
              alt={starName}
              className="star-image-img"
            />
          </div>
          <div>
            <div className="prize-text">Won a livestream with</div>
            <div className="star-name">{starName}</div>
          </div>
        </div>
        {rank === 1 ? (
          <div>
            <div className="prize-text">Won cash</div>
            <div className="star-name">₹10,00,000</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default WinnerCard
