import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import formatNumber from '../../lib/format-number'
import './GetMegaCarromParty.scss'

export interface Participant {
  Name: string
  'Youtube Link': string
  Views: number
  Picture: string
}

interface Props {
  lbState: string
  participants: Participant[]
}

const CarromPartyLeaderboard: React.FC<Props> = ({ lbState, participants }) => {
  let displayedParticipants = participants
  const [showAll, setShowAll] = useState(false)

  if (!showAll) {
    displayedParticipants = participants.slice(0, 10)
  }

  return (
    <div className="lb-column">
      <div className="d-none d-lg-block">
        <div className="form">
          <div>
            <div className="t">
              All excited to win our next YouTube Challenge?
            </div>
            <div className="st">Register to stay updated!</div>
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScNL4rBD3W9LlZQBlDbEzrzl5xidh32mXz4bO9ueG0BZdZQvg/viewform"
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            Register Now
          </a>
        </div>
      </div>
      <div className="leaderboard-container">
        <div className="lb">Leaderboard</div>
        <div
          className="leaderboard"
          style={{
            minHeight: lbState === 'Ended' ? '266px' : 'auto',
          }}
        >
          {lbState === 'Started' ||
          lbState === 'Ended' ||
          lbState === 'Result Declared' ? (
            <div className="Started">
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Views</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedParticipants.map((p, i) => {
                    return (
                      <tr key={`${p.Name}-${i + 1}`}>
                        <td>
                          <span>{i + 1}</span>
                          <img
                            src={
                              p.Picture ||
                              'https://img1.getmega.com/default_user_dp.png'
                            }
                            className="participant-image"
                            alt="Channel Icon"
                          />
                        </td>
                        <td className="participant-name">
                          <a
                            href={p['Youtube Link']}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>{p.Name}</span>
                            {/* {lbState === 'Result Declared' ? (
                              <span className="black">Rank {i + 1} Winner</span>
                            ) : null} */}
                          </a>
                        </td>
                        <td>{formatNumber(p.Views)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

              {participants.length === 0 ? (
                <div className="no-participants">Loading Leaderboard...</div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="show-all-wrap">
          <Button
            variant="link"
            className="show-all-btn"
            onClick={() => {
              setShowAll(!showAll)
            }}
          >
            {showAll ? 'Hide' : 'Show'} Full Leaderboard
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CarromPartyLeaderboard
