import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import './GetMegaCarromParty.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { formatForBigQuery, isMobile } from '../../formatForBigQuery'
import CarromPartyLeaderboard from './CarromPartyLeaderboard'
import WinnerCard from './WinnerCard'

type LeaderboardState = 'Not Started' | 'Started' | 'Ended' | 'Result Declared'

interface Leaderboard {
  Name: string
  'Youtube Link': string
  Views: number
  Picture: string
}

interface CampaignDetails {
  State: LeaderboardState
  StartTime: number
  EndTime: number
  ResultTime: number
  LastUpdatedAt: number
  OriginalEndTime: number
}

type LoadingStatus = 'LOADING' | 'SUCCESS'

const MegaCarromParty: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      yt: file(relativePath: { eq: "youtube-small.png" }) {
        childImageSharp {
          gatsbyImageData(width: 14, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      banner: file(relativePath: { eq: "CarromPartyYoutube.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      tenlac: file(relativePath: { eq: "10-lac.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      tenlac2: file(relativePath: { eq: "10-lac2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 328, placeholder: NONE, layout: FIXED)
        }
      }
      LiveInsaan: file(relativePath: { eq: "live-insaan.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: NONE, layout: FIXED)
        }
      }
      Fukra: file(relativePath: { eq: "Fukra Insaan Live.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: NONE, layout: FIXED)
        }
      }
      DhiruMonchik: file(relativePath: { eq: "DhiruMonchik.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: NONE, layout: FIXED)
        }
      }
      Tanmay: file(relativePath: { eq: "Tanmay Bhat Photo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
        }
      }
      Suhani: file(relativePath: { eq: "Suhani Shah.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
        }
      }
      Nishant: file(relativePath: { eq: "Nishant Image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
        }
      }
      TanmayYT: file(relativePath: { eq: "Tanmay-yt.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      ytRed: file(relativePath: { eq: "youtube-red.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      instaRed: file(relativePath: { eq: "instagram-red.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      twitter: file(relativePath: { eq: "twitter-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      leaves: file(relativePath: { eq: "leaves.png" }) {
        childImageSharp {
          gatsbyImageData(width: 20, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `)

  const timer = useRef<NodeJS.Timer>()

  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails>()
  const [status, setStatus] = useState<LoadingStatus>('LOADING')
  const [leaderboard, setLeaderboard] = useState<Leaderboard[]>([])

  const getLeaderboardData = useCallback(async () => {
    const endpoint = 'https://youtube-leaderboard.getmega.workers.dev/'
    const controller = new AbortController()
    const options = {
      signal: controller.signal,
    }
    const timeoutId = setTimeout(() => controller.abort(), 60 * 1000)
    const cfResponse = await fetch(endpoint, options)
      .then(response => response.json())
      .then(d => d)
      .catch(err => {
        console.error(err)
        setStatus('SUCCESS')
      })
      .finally(() => {
        clearTimeout(timeoutId)
      })

    if (cfResponse) {
      cfResponse.campaignDetails.StartTime = new Date(
        cfResponse.campaignDetails?.StartTime * 1000
      )
      cfResponse.campaignDetails.EndTime = new Date(
        cfResponse.campaignDetails?.EndTime * 1000
      )
      cfResponse.campaignDetails.ResultTime = new Date(
        cfResponse.campaignDetails?.ResultTime * 1000
      )
      cfResponse.campaignDetails.LastUpdatedAt = new Date(
        cfResponse.campaignDetails?.LastUpdatedAt * 1000
      )
      cfResponse.campaignDetails.OriginalEndTime = new Date(
        cfResponse.campaignDetails?.OriginalEndTime * 1000
      )

      setCampaignDetails(cfResponse.campaignDetails)
      const lbData = cfResponse.leaderboard
      const lb = lbData.map(
        (video: {
          details: {
            ChannelName: string
            Views: number
            ChannelImageUrl: string
          }
          url: string
        }) => ({
          Name: video.details.ChannelName,
          'Youtube Link': video.url,
          Views: video.details.Views,
          Picture: video.details.ChannelImageUrl,
        })
      )
      setLeaderboard(lb)
      setStatus('SUCCESS')
    }
  }, [])

  const lbState = campaignDetails?.State || 'Started'

  useEffect(() => {
    getLeaderboardData()
  }, [getLeaderboardData])

  useEffect(() => {
    if (status === 'SUCCESS') {
      if (lbState === 'Started') {
        timer.current = setInterval(() => {
          getLeaderboardData()
        }, 60 * 1000)
      } else {
        clearInterval(timer.current)
      }
    }
    return function cleanup() {
      clearInterval(timer.current)
    }
  }, [getLeaderboardData, lbState, status])

  const participants = leaderboard.sort((a, b) => {
    if (a.Views > b.Views) return -1
    if (a.Views < b.Views) return 1
    return 0
  })

  return (
    <div className="GetMegaCarromPartyContainer">
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div>
              <h1 className="get-mega-carrom-party">#GetMegaCarromParty</h1>
              <div className="wp-text">
                <GatsbyImage
                  image={data.leaves.childImageSharp.gatsbyImageData}
                  alt=""
                />
                <div className="wp-text-inner">
                  <div className="w-text">Contest Winners</div>
                  <div className="p-text">TOP PRIZES</div>
                </div>
                <GatsbyImage
                  image={data.leaves.childImageSharp.gatsbyImageData}
                  alt=""
                  className="wp-img-2"
                />
              </div>
              <div className="d-none d-lg-block">
                <GatsbyImage
                  image={data.tenlac2.childImageSharp.gatsbyImageData}
                  alt=""
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <GatsbyImage
                image={data.banner.childImageSharp.gatsbyImageData}
                alt="banner"
              />
            </div>
          </Col>
        </Row>

        <div className="d-block d-lg-none cust-b1">
          <GatsbyImage
            image={data.tenlac.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>

        <Row className="d-block d-lg-none">
          <Col>
            <div className="form">
              <div className="t">
                All excited to win our next YouTube Challenge?
              </div>
              <div className="st">Register to stay updated!</div>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScNL4rBD3W9LlZQBlDbEzrzl5xidh32mXz4bO9ueG0BZdZQvg/viewform"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Register Now
              </a>
            </div>
          </Col>
        </Row>

        <div className="winners-section">
          <div className="winners-card-wrap">
            <WinnerCard
              rank={1}
              winner="Live Insaan"
              winnerImage={data.LiveInsaan.childImageSharp.gatsbyImageData}
              views={`${participants?.[0]?.Views || '16,56,483'}`}
              starName="Tanmay Bhat"
              starImage={data.Tanmay.childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="winners-card-wrap">
            <WinnerCard
              rank={2}
              winner="Fukra Insaan Live"
              winnerImage={data.Fukra.childImageSharp.gatsbyImageData}
              views={`${participants?.[1]?.Views || '2,63,272'}`}
              starName="Suhani Shah"
              starImage={data.Suhani.childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="winners-card-wrap">
            <WinnerCard
              rank={3}
              winner="DhiruMonchik"
              winnerImage={data.DhiruMonchik.childImageSharp.gatsbyImageData}
              views={`${participants?.[2]?.Views || '1,68,396'}`}
              starName="Nishant Tanwar"
              starImage={data.Nishant.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>

        <Row>
          <Col xs={12} lg={6} className="highlights">
            <h2 className="h-title">
              Thanks to your support, the #GetMegaCarromParty Challenge was a
              huge hit
            </h2>
            <h3>Here’s how:</h3>
            <div className="h-highlights">
              <div className="h-image">
                <GatsbyImage
                  image={data.TanmayYT.childImageSharp.gatsbyImageData}
                  alt="Tanmay"
                  imgStyle={{ objectFit: 'contain' }}
                  className="img"
                />
              </div>
              <div className="t">
                The Challenge was launched by Tanmay Bhat with never-seen-before
                moments
              </div>
              <div className="v-line-wrap">
                <div className="v-line" />
              </div>
              <div className="info">
                <div className="desc">
                  Surprise guest entry of{' '}
                  <span className="b">The Great Khali</span> that went viral
                </div>
                <div className="desc">
                  <span className="b">£500 Super Chat</span> that broke all
                  records for YouTube Super Chat in India.
                </div>
                <div className="desc">
                  This stream racked up around{' '}
                  <span className="b">400,000+ views</span>
                </div>
              </div>

              <div className="h-image">
                <GatsbyImage
                  image={data.ytRed.childImageSharp.gatsbyImageData}
                  alt="youtube"
                  imgStyle={{ objectFit: 'contain' }}
                  className="img"
                />
              </div>
              <div className="t">Youtube</div>
              <div className="v-line-wrap">
                <div className="v-line" />
              </div>
              <div className="info">
                <div className="desc">
                  <span className="b">100+</span> Creators
                </div>
                <div className="desc">
                  <span className="b">3,000,000+</span> Views
                </div>
                <div className="desc">
                  <span className="b">7,700+</span> Comments
                </div>
                <div className="desc">
                  <span className="b">1,000,000+</span> live chat responses
                </div>
                <div className="desc">
                  <span className="b">55,000</span> concurrent watching for a
                  video
                </div>
              </div>

              <div className="h-image">
                <GatsbyImage
                  image={data.instaRed.childImageSharp.gatsbyImageData}
                  alt="youtube"
                  imgStyle={{ objectFit: 'contain' }}
                  className="img"
                />
              </div>
              <div className="t">Instagram</div>
              <div className="v-line-wrap">
                <div className="v-line" />
              </div>
              <div className="info">
                <div className="desc">
                  <span className="b">21,000,000+</span> Views
                </div>
                <div className="desc">
                  <span className="b">6,000+</span> Comments
                </div>
                <div className="desc">
                  <span className="b">1,800+</span> Posts
                </div>
                <div className="desc">
                  <span className="b">1,000+</span> Stories
                </div>
              </div>

              <div className="h-image">
                <GatsbyImage
                  image={data.twitter.childImageSharp.gatsbyImageData}
                  alt="youtube"
                  imgStyle={{ objectFit: 'contain' }}
                  className="img"
                />
              </div>
              <div className="t">Twitter</div>
              <div className="v-line-wrap">
                <div className="v-line" />
              </div>
              <div className="info">
                <div className="desc">
                  <span className="b">2</span> Trends across{' '}
                  <span className="b">48 hours</span>
                </div>
                <div className="desc">
                  <span className="b">7,000+</span> Tweets
                </div>
                <div className="desc">
                  <span className="b">20,000,000+</span> reached
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} className="mobile-lb d-none d-lg-block">
            <CarromPartyLeaderboard
              lbState={lbState}
              participants={participants}
            />
          </Col>
        </Row>
      </Container>

      <div className="d-block d-lg-none white-bg">
        <Container>
          <Row>
            <Col className="no-padding">
              <CarromPartyLeaderboard
                lbState={lbState}
                participants={participants}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MegaCarromParty
